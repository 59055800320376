<template>
  <component :is="blockComponents[block.item.template]" :block="block" :class="`ag-block--note-${block.item.template}`" />
</template>

<script setup lang="ts">
defineProps<{
  block: Block;
}>();

const blockComponents = {
  simple: resolveComponent("LazySimpleNote"),
  simple2: resolveComponent("LazySimpleNote2"),
  simple3: resolveComponent("LazySimpleNote3"),
  simple4: resolveComponent("LazySimpleNote4"),
  simple5: resolveComponent("LazySimpleNote5"),
};
</script>

<style lang="postcss">
.ag-block--note {
  .ag-block__title a {
    @apply no-underline;
  }

  .ag-block__title {
    @apply mb-2;
  }

  .ag-block__description {
    @apply mb-0;
  }

  .ag-block__cta {
    @apply mt-5;
  }
}
</style>
